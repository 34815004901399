import "../styles/Promotion.scss";

const Promotion = ({ openModal }: any) => {
  return (
    <div className="Promotion">
      <div className="Promotion_body">
        <div className="Promotion_card">
          <div className="Promotion_bg">
            <img src={process.env.PUBLIC_URL + "prPomotion.webp"} alt="" className="Promotion_img" />
            <div className="Promotion_card_text">
              <h2 className="Promotion_title">Спеціальна пропозиція</h2>
              <p className="Promotion_name">Акумуляторна батарея LPBF48300</p>
              <h3 className="Promotion_text_price">
                <span className="Promotion_text_old_price">3160$</span>
                2300$
                <span className="Promotion_text_discount">-27%</span>
              </h3>
              <button className="Promotion_btn_card" onClick={openModal}>
                Замовити
              </button>
              <p className="small_text_bottom">
                Напишіть в повідомленні "Акція" і наш менеджер одразу сформує вам спеціальну пропозицію.
              </p>
            </div>
          </div>
          <div className="Promotion_blob"></div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
